// Category and game form grups.
var $casinoCategoryGames = $('div#casino-category-games');
var $sortableContainer = $('div.sortable-container', $casinoCategoryGames);
var $checkboxesContainer  = $('div#casino-game-categories div.scrollable-checkboxes, div#casino-category-games div.scrollable-checkboxes');
var $checkboxes = $('div.casino-category-group input[type="checkbox"]', $checkboxesContainer);
var $website = $('#website').attr('value');
var dragStartElementId = null;

//Old logic for drag and drop performs issus
// $sortableContainer.sortable({
//   containment: 'parent',
//   handle: '.move-handle',
//   revert: true,
//   cursor: 'move'
// });

// New logic for sortable
$('.casino-game-group.selected').draggable({
  refreshPositions: true,
   axis: "y",
   start: function( event, ui ) {
     dragStartElementId = $(event.target).index()
   }
});

$('.casino-game-group.selected').droppable({
  drop: function( event, ui ) {
    if (!($(this).children(".drag").size() == 1)) {
      if(dragStartElementId > $(this).index()) {
        $(ui.draggable).insertBefore($(this));
        ui.draggable.css({
            left: 0,
            top: 0,
        });
        return;
      }

      $(ui.draggable).insertAfter($(this));
        ui.draggable.css({
            left: 0,
            top: 0,
        });
    }
  },
})

initSortableForProviders();

function initSortableForProviders() {
  parseAllProvidersValue();
  $("#all-providers > ul").sortable({
    update: function( event, ui ) { formatCasinoProviders() }
  });
  formatCasinoProviders();

  setSortableOnAllCasinoProviders();
}

function setSortableOnAllCasinoProviders() {
  $("#all-providers > ul").sortable({
    update: function( event, ui ) { formatCasinoProviders() }
  });
}

function parseAllProvidersValue() {
  var rawValue = $("#all-providers-value").val();

  if (!rawValue) {
    return;
  }

  var allProviders = rawValue.split(',');

  if (!allProviders.length) {
    return;
  }

  allProviders.forEach(function (provider, key) {
    $("#all-providers > ul").append('<li>' + provider + '<input type="hidden" class="provider-key form-control" value="' + provider + '"></input></li>');
  });
}

function formatCasinoProviders() {
  var rawValue = $('#controll-providers-value').val();

  if (!rawValue) {
    return;
  }

  var controllProviders = rawValue.split(',');

  var result = [];

  $("#all-providers > ul li").each(function () {
    let value = $(this).find('.provider-key').val();
    let index = controllProviders.findIndex(e => e === value);

    if (index === -1) {
      return;
    }

    result.push({
      'provider': index,
    });
  });


  $("#all-providers-value").val(JSON.stringify(result));
}

function createTableIdField(inputField, value = '') {
  $('.table-id-input').remove();

  if (!inputField.hasClass('has-table')) {
    return;
  }


  return $('<div class="form-group table-id-input"> ' +
    '<label>Table Id</label>' +
    '<input type="text" class="form-control table-id-input" name="table_id" value="' + value + '">').insertAfter(inputField);
}

$checkboxes.each(function() {
  var $this = $(this);
  var $orderInput = $('input[id^=order_from_category-]', $this.closest('div.casino-category-group'));

  if (this.checked) {
    $orderInput.removeAttr('disabled');
    $orderInput.closest('div.casino-order').removeClass('hidden');
  }
});

$('li.casino-game-group input[type="checkbox"]', $checkboxesContainer).on('click', function() {
  var $this = $(this);
  var $gameContainer = $this.closest('li.casino-game-group');
  var $moveHandle = $('span.move-handle', $gameContainer);
  var $notSelectedSpan = $this.closest('li.casino-game-group').children().children('span');

  if ($notSelectedSpan.hasClass('not-selected-games')) {
    $notSelectedSpan.toggleClass('move-handle');
    $notSelectedSpan.toggleClass('hidden');
    $('.casino-game-group.selected').draggable('refresh');

    return;
  }

  $moveHandle.toggleClass('hidden');

  var $lastSelectedGameContainer = $('li.casino-game-group.selected:last', $casinoCategoryGames);
  $gameContainer.insertAfter($lastSelectedGameContainer);
  $gameContainer.toggleClass('selected');
});

$('div.casino-category-group input[type="checkbox"]', $checkboxesContainer).on('click', function() {
  var $this = $(this);
  var $orderInput = $('input[id^=order_from_category-]', $this.closest('div.casino-category-group'));

  if (this.checked) {
    $orderInput.removeAttr('disabled');
    $orderInput.closest('div.casino-order').removeClass('hidden');
  } else {
    $orderInput.attr('disabled', 'disabled');
    $orderInput.closest('div.casino-order').addClass('hidden');
  }
});

$('div#casino-game-categories a.expander-handle, div#casino-category-games a.expander-handle').on('click', function () {
  $this = $(this);
  var $icon = $('i', $this);

  if ($icon.hasClass('fa-expand')) {
    $checkboxesContainer.css('height', 'initial');
    $('i', $this).removeClass('fa-expand').addClass('fa-compress');
  } else {
    $checkboxesContainer.removeAttr('style');
    $('i', $this).removeClass('fa-compress').addClass('fa-expand');
  }
});

// Games list filter.
$("#games-filter").change(function(){
  var $this = $(this);
  var filter = $this.val();
  var url = $this.data('url');

  if (filter !== 'all') {
    var index = url.indexOf('?');
    var firstPart = url.slice(0, index);
    var secondPart =  url.slice(index, url.length + 1);
    url = firstPart + `/filter/${filter}` + secondPart;
  }

  window.location = url;
});

// Navigation tabs.
var $navigationTabs = $('div#casino-navigation-tabs');
var $addedNavigationTabs = $('div#added-navigation-tabs', $navigationTabs);
var $cloningGroup = $('div[data-role="tab-group-for-cloning"]', $navigationTabs);

function reindexTabsPosition() {
 // Fix multiple elements indexes which are later used for the ordering.
  $('div[data-role="tab-container"]', $addedNavigationTabs).each(function(tabIndex) {
    $('input, select', $(this)).each(function() {
      var $this = $(this);
      var name = $this.attr('name');
      if (name) {
        $this.attr('name', name.replace(/\]\[-*\d+\]\[/g, '][' + tabIndex + ']['));
      }
    });
  });
}

  // Adding a new tab.
$addedNavigationTabs.on('click', 'a[data-role="add-tab-handle"]', function() {
  var $this = $(this);
  var $tabContainer = $this.closest('div[data-role="tab-container"]');

  var $clone = $cloningGroup.clone();
  $clone.removeClass('hidden');
  $('input[type="text"], select', $clone).each(function() {
    if ($(this).data('role') !== 'type-url-input') {
      $(this).removeAttr('disabled');
    }
  });

  $clone.find('.tab_name_translations').prop('disabled', true);
  $clone.find('.tab_name_main').keyup(function() {
    var value = $(this).val().toLowerCase().replace(" ", "_");
    $(this).parent().siblings('.tab_name_translation_input').children('div').data('key', value);
    $clone.find('.tab_name_translations').siblings('div').removeClass(`tab_name_translation_`)
    $clone.find('.tab_name_translations').siblings('div').addClass(`tab_name_translation_${value}`)
    $clone.find('.tab_name_translations').attr('id', `tab_name_${value}`);
    $clone.find('.tab_name_translations').val(value)
    $clone.find(`.tab_name_translation_${value}`).first().load(`/translations/casino_page.${value}`);
  });
  $('input[type="radio"]:first', $clone).prop('checked', true);

  if ($tabContainer.length) {
    $tabContainer.before($clone);
  } else {
    $this.before($clone);
  }

  $('#text-input', $clone).focus();
  reindexTabsPosition();
});

  // Removing an tab.
$addedNavigationTabs.on('click', 'a[data-role="remove-tab-handle"]', function(e) {
  e.preventDefault();
  var $tabContainer = $(this).closest('div[data-role="tab-container"]');
  var $selectElemenet = $('select[data-role="type-category-input"]', $tabContainer);

  if (bootbox === undefined) {
    enableSelectCategory($selectElemenet);
    $tabContainer.remove();
    reindexTabsPosition();
  } else {
    bootbox.dialog({
      message: 'Are you sure you want to remove this tab?',
      title: 'Are you sure?',
      buttons: {
        success: {
          label: 'Cancel',
          className: 'btn-default pull-left',
          callback: function() {
            bootbox.hideAll();
          }
        },
        danger: {
          label: 'Yes',
          className: 'btn-danger',
          callback: function() {
            enableSelectCategory($selectElemenet);
            $tabContainer.remove();
            reindexTabsPosition();
          }
        }
      }
    });
  }
});

  // Changing tab type.
$addedNavigationTabs.on('click', 'input[data-role="type-handle"]', function () {
  var $this = $(this);
  var $tabContainer = $this.closest('div[data-role="tab-container"]');
  var $categorySelect = $('[data-role="type-category-input"]', $tabContainer);
  var $urlInput = $('[data-role="type-url-input"]', $tabContainer);

  if ($this.val() === 'url') {
    $urlInput.removeAttr('disabled').removeClass('hidden');
    $categorySelect.addClass('hidden').attr('disabled', 'disabled');
  } else {
    $categorySelect.removeAttr('disabled').removeClass('hidden');
    $urlInput.addClass('hidden').attr('disabled', 'disabled');
  }
});

function enableSelectCategory($selectElement) {
  var value = $selectElement.val();
  var $otherSelects = $('select[data-role="type-category-input"]', $navigationTabs).not($selectElement);

  if (value) {
    $otherSelects.find(`option[value="${value}"]`).attr('disabled', false);
  }
}

function disableSelectedCategories($selectElement) {
  var previousValue = $selectElement.data('previous-value');
  var value = $selectElement.val();
  var $otherSelects = $('select[data-role="type-category-input"]', $navigationTabs).not($selectElement);

  if (value) {
    $otherSelects.find(`option[value="${value}"]`).attr('disabled', true);
  }

  if (previousValue) {
    $otherSelects.find(`option[value="${previousValue}"]`).attr('disabled', false);
  }

  $selectElement.data('previous-value', value ? value : '');
}

  // Disable selected categories in the other select boxes.
$('select[data-role="type-category-input"]', $navigationTabs).each(function() {
  disableSelectedCategories($(this));
});

  // Selecting category - disable selected in the other select boxes.
$addedNavigationTabs.on('change', 'select[data-role="type-category-input"]', function () {
  disableSelectedCategories($(this));
});

//casino navigation new category modal
function loadNewCategoryModal() {
  var $websiteId = $('body #website').val();

  var data = {
    isMobile: $('body #isMobile').val()
  };

  var $url = `/websites/${$websiteId}/casino-categories/create`;

  loadModal($url, data);
}

function loadNewCategoryPlaytechModal() {
  var $websiteId = $('body #website').val();

  var data = {
    isMobile: $('body #isMobile').val()
  };

  var $url = `/websites/${$websiteId}/casino-live-categories/create`;
  loadModal($url, data, true);
}

function loadModal($url, data, isPlaytech = false) {
  $.get(
    $url,
    data,
    function(response) {
      if (isPlaytech) {
        printNewCategoryPlaytechModal(response);
        openNewCategoryPlaytechModal();
      } else {
        printNewCategoryModal(response);
        openNewCategoryModal();
      }
      $('body form #phrase_key').keyup(function() {
        var value = $(this).val().toLowerCase().replace(" ", "_");
        $(this).val(value);

        phraseKeyInputDelay(function() {
          $('body form .phrase_key_translation').first().load(`/translations/casino_page.${value}`);
        }, 600);
      });
      $('form #phrase_key').trigger('keyup');

      $('body form #url_key').keyup(function() {
        var value = $(this).val().toLowerCase().replace(" ", "_");
        $(this).val(value);

        urlKeyInputDelay(function() {
          $('body form .url_key_translation').first().load(`/translations/url.${value}`);
        }, 600);
      });
      $('form #url_key').trigger('keyup');
    });
}

function printNewCategoryModal(html) {
  $('#printNewCategoryModal').html(html);
}

function printNewCategoryPlaytechModal(html) {
  $('#printNewCategoryPlaytechModal').html(html);
}

function closeNewCategoryModal() {
  $('#categoriesModal').modal('hide');
}

function closeNewPlaytechCategoryModal() {
  $('#categoriesPlaytechModal').modal('hide');
}

function closeNewCategoryPlaytechModal() {
  $('#categoriesPlaytechModal').modal('hide');
}

function openNewCategoryPlaytechModal() {
  $('#categoriesPlaytechModal').modal('show');
}

function openNewCategoryModal() {
  $('#categoriesModal').modal('show');
}

$('body').on('submit', '#categoriesForm', function(e) {
    var $this = $(this);
    var form = new FormData($this[0]);
    e.preventDefault();

    $.ajax({
      url : $this.attr('action'),
      type: 'POST',
      data: form,
      processData: false,
      contentType: false,
      success: function(response) {
        if (response === 'Category already exists.') {
          message = `<div class="alert alert-danger">${response}</div>`;
        } else {
          message = `<div class="alert alert-success">${response}</div>`;
        }
        closeNewCategoryModal();
        $('#responseMessage').html(message);
        setTimeout('location.reload(true)', 1000);
      },
      error: function(data) {
        $errors = data.responseJSON;
        errorsHtml = `<div class="alert alert-danger"><ul>`;

        $.each($errors, function(key, value) {
            errorsHtml += `<li>${value[0]}</li>`;
        });
        errorsHtml += `</ul></di>`;
        $('body #categories-modal').animate({ scrollTop: 0 }, 'fast');
        $('#categories-modal-form-errors').html(errorsHtml);
      }
    });
});

$(document).ready(function() {
  if (! $('#micro_gaming_new_type').is(':checked')) {
    checkIfNewMicroGaming();
  }
  $( "#datepicker" ).datepicker({
    format: "mm/dd/yy",
    weekStart: 0,
    calendarWeeks: true,
    autoclose: true,
    todayHighlight: true,
    rtl: true,
    orientation: "auto"
    });
});

$(document).ready(function() {
  if (! $('#micro_gaming_vegas_type').is(':checked')) {
    checkIfVegasMicroGaming();

    $( "#datepicker" ).datepicker({
      format: "mm/dd/yy",
      weekStart: 0,
      calendarWeeks: true,
      autoclose: true,
      todayHighlight: true,
      rtl: true,
      orientation: "auto"
      });
  }
});

$(document).ready(function() {
  Promise.all($('.tab_name_translations').map(function() {
    let elem = $(this);
    elem.prop("disabled", true );

    return new Promise(function () {
      let data = elem.val();
      $(`body form .tab_name_translation_${data}`).first().load(`/translations/casino_page.${data}`);
    })
  }));

  $('.tab_name_main').map(function() {
    $(this).keyup(function() {
      var datakey = $(this).parent().siblings('.tab_name_translation_input').children('div').data('key');
      var value = $(this).val().toLowerCase().replace(" ", "_");
      var placeholderInput = $(`body form .tab_name_translation_${datakey}`);

      placeholderInput.siblings('input').attr('id', `tab_name_${value}`);
      placeholderInput.siblings('input').val(value)
      placeholderInput.removeClass(`tab_name_translation_${datakey}`)
      placeholderInput.addClass(`tab_name_translation_${value}`)
      placeholderInput.data('key', value)
      placeholderInput.first().load(`/translations/casino_page.${value}`);
    });
  })
})


$(document).ready(function() {
  if (! $('#evolution_new_type').is(':checked')) {
    checkIfNewEvolutionType();
  }
});

$(document).ready(function() {
  if (! $('#pragmatic_casino_type').is(':checked')) {
    checkIfPragmaticType();
  }
});

$(document).ready(function() {
  if (! $('#playtech_live_type').is(':checked')) {
    checkIfPlaytechLiveType();
  }
});

$(document).ready(function() {
  if (! $('#oryx_type').is(':checked')) {
    checkIfOryxGaming();
  }
});

$(document).ready(function() {
  if (! $('#leap_virtuals_type').is(':checked')) {
    checkIfLeapCasino();
  }
});


$(document).ready(function() {
  if (! $('#meridian_casino_type').is(':checked')) {
    checkIfMeridianGame();
  }
});

$(document).ready(function() {
  if (! $('#bet_games_type').is(':checked')) {
    checkIfBetGames();
  }
});

$(document).ready(function() {
  if (! $('#golden_race_casino').is(':checked')) {
    checkIfGoldenRace();
  }
});


$(document).ready(function() {
  if (! $('#playtech_casino_type').is(':checked')) {
    checkIfPlaytech();
  }
});

$(document).ready(function() {
  if (! $('#micro_gaming_new_type').is(':checked')) {
    checkIfMicroGaming();
  }
});

$(document).ready(function() {
  if (! $('#micro_gaming_vegas_type').is(':checked')) {
    checkIfVegasMicroGamingInput();
  }
});

$(document).ready(function() {
  if (! $('#blue_ocean_casino_type').is(':checked')) {
    checkIfBlueOcean();
  }
});

$(document).ready(function() {
  if (! $('#i_soft_bet_provider_type').is(':checked')) {
    checkIfISoftBet();
  }
});

$(document).ready(function() {
  if (! $('#relax_casino_type').is(':checked')) {
    checkIfRelax();
  }
});

$(document).ready(function() {
  if (! $('#synot_casino_type').is(':checked')) {
    checkIfSynot();
  }
});

$(document).ready(function() {
  if (! $('#is_ticket_banner_game').is(':checked')) {
    const isTicketBannerGame = !!$('#ticket_banner_id').val();
    if (isTicketBannerGame) {
      $('#ticket_banner_casino_game').show();
      $('#is_ticket_banner_game').prop('checked', false);

      return;
    }

    $('#is_ticket_banner_game').prop('checked', true)
    $('#ticket_banner_casino_game').hide();
  }
});

$('input[type=radio][name=game-type]').change(function() {
  checkIfNewMicroGaming(this);
  checkIfOryxGaming(this);
  checkIfNewEvolutionType(this);
  checkIfMeridianGame(this);
  checkIfBetGames(this);
  checkIfLeapCasino(this);
  checkIfPlaytech(this);
  checkIfMicroGaming(this);
  checkIfBlueOcean(this);
  checkIfGoldenRace(this);
  checkIfRelax(this);
  checkIfSynot(this);
  checkIfISoftBet(this);
  checkIfVegasMicroGamingInput(this);
  checkIfVegasMicroGaming(this);
  checkIfPlaytechLiveType(this);
  checkIfPragmaticType(this);
});

$('input[type=checkbox][name=ticket_banner_casino]').change(function() {
  checkIfTicketBannerCasinoGame(this);
})

function checkIfPlaytechLiveType(element = '') {
  if (element.id === 'playtech_live_type') {
    $('#playtech-live-info-checkbox').show();
  } else {
    $('#playtech-live-info-checkbox').hide();
  }
}

function checkIfPragmaticType(element = '') {
  if (element.id === 'pragmatic_casino_type') {
    $('#pragmatic-live-info-checkbox').show();
    $('.is_playtech_info_enabled_pragmatic').prop('checked', true)
  } else {
    $('.is_playtech_info_enabled_pragmatic').prop('checked', false)
    $('#pragmatic-live-info-checkbox').hide();
  }
}

function checkIfNewMicroGaming(element = '') {
  if (element.id === 'micro_gaming_new_type') {
    $('#module_id').prop('disabled', false);
    $('#client_id').prop('disabled', false);
    $('#product_id').prop('disabled', false);
    $('#drop-down-id').prop('disabled', false);
    $("#micro_gaming_new").show()
  } else {
    $('#module_id').prop('disabled', true);
    $('#client_id').prop('disabled', true);
    $('#product_id').prop('disabled', true);
    $('#drop-down-id').prop('disabled', true);
    $("#micro_gaming_new").hide()
    clearInputs();
  }
}

function checkIfVegasMicroGaming(element = '') {
  if (element.id === 'micro_gaming_vegas_type') {
    $('#micro_gaming_vegas #module_id').prop('disabled', false);
    $('#micro_gaming_vegas #client_id').prop('disabled', false);
    $('#micro_gaming_vegas #product_id').prop('disabled', false);
    $('#micro_gaming_vegas #drop-down-id').prop('disabled', false);
    $("#micro_gaming_vegas").show()
  } else {
    $('#micro_gaming_vegas #module_id').prop('disabled', true);
    $('#micro_gaming_vegas #client_id').prop('disabled', true);
    $('#micro_gaming_vegas #product_id').prop('disabled', true);
    $('#micro_gaming_vegas #drop-down-id').prop('disabled', true);
    $("#micro_gaming_vegas").hide()
    clearInputsVegas();
  }
}

function checkIfBetGames(element = '') {
  if(element.id === 'bet_games_type') {
    $('#bet_games').show();
    $('#outside_casino').prop('checked', true);
  } else {
    $('#outside_casino').prop('checked', false);
    $('#bet_games').hide();
  }
}

function checkIfGoldenRace(element = '') {
  if(element.id === 'golden_race_casino') {
    $('#golden_race').show();
    $('#golden_race_outside_casino').prop('checked', true);
  } else {
    $('#golden_race_outside_casino').prop('checked', false);
    $('#golden_race').hide();
  }
}

function checkIfMeridianGame(element = '') {
  if(element.id === 'meridian_casino_type') {
    $('#meridianbet_game').show();
  } else {
    $('#drop-down').prop('selectedIndex',0);
    $('#meridianbet_game').hide();
  }
}

function checkIfNewEvolutionType(element = '') {
  if (element.id === 'evolution_new_type') {
    $('#drop-down-id-evolution-new').show();
    $('#drop-down-id-evolution_new').show();
    $('#evolution-live-info-checkbox').show();
    $('.is_playtech_info_enabled_evolution').prop('checked', true)
  } else {
    $('.is_playtech_info_enabled_evolution').prop('checked', false)
    $('#drop-down-id-evolution-new').hide();
    $('#drop-down-id-evolution_new').hide();
    $('#evolution-live-info-checkbox').hide();
  }
}

function checkIfOryxGaming (element = '') {
  if(element.id === 'oryx_type') {
    $('#drop-down-id-oryx').show();
  } else {
    $('#drop-down-id-oryx').hide();
    $('#drop-down-id-oryx').prop('selectedIndex',0);
  }
}

function checkIfLeapCasino (element = '') {
  if(element.id === 'leap_virtuals_type') {
    $('#drop-down-id-leap').show();
  } else {
    $('#drop-down-id-leap').hide();
    $('#drop-down-id-leap').prop('selectedIndex',0);
  }
}

function checkIfPlaytech (element = '') {
  if(element.id === 'playtech_casino_type') {
    $('#drop-down-id-playtech').show();
  } else {
    $('#drop-down-id-playtech').hide();
    $('#drop-down-id-playtech').prop('selectedIndex',0);
  }
}

function checkIfMicroGaming (element = '') {
  if(element.id === 'micro_gaming_new_type') {
    $('#drop-down-id-microgaming').show();
  } else {
    $('#drop-down-id-microgaming').hide();
    $('#drop-down-id-microgaming').prop('selectedIndex',0);
  }
}

function checkIfVegasMicroGamingInput (element = '') {
  if(element.id === 'micro_gaming_vegas_type') {
    $('#drop-down-id-microgaming-vegas').show();
  } else {
    $('#drop-down-id-microgaming-vegas').hide();
    $('#drop-down-id-microgaming-vegas').prop('selectedIndex',0);
  }
}

function checkIfBlueOcean (element = '') {
  if(element.id === 'blue_ocean_casino_type') {
    $('#drop-down-id-blue-ocean').show();
  } else {
    $('#drop-down-id-blue-ocean').hide();
    $('#drop-down-id-blue-ocean').prop('selectedIndex',0);
  }
}

function checkIfISoftBet (element = '') {
  if(element.id === 'i_soft_bet_provider_type') {
    $('#drop-down-id-i-soft-bet').show();
  } else {
    $('#drop-down-id-i-soft-bet').hide();
    $('#drop-down-id-i-soft-bet').prop('selectedIndex',0);
  }
}

function checkIfRelax (element = '') {
  if(element.id === 'relax_casino_type') {
    $('#drop-down-id-relax').show();
  } else {
    $('#drop-down-id-relax').hide();
    $('#drop-down-id-relax').prop('selectedIndex',0);
  }
}

function checkIfSynot (element = '') {
  if(element.id === 'synot_casino_type') {
    $('#drop-down-id-synot').show();
  } else {
    $('#drop-down-id-synot').hide();
    $('#drop-down-id-synot').prop('selectedIndex',0);
  }
}

function checkIfTicketBannerCasinoGame (element = '') {
  if ( $('#is_ticket_banner_game').is(':checked')) {
    $('#ticket_banner_casino_game').show();
    $('#ticket_banner_casino_game').prop('selectedIndex',0);
    $('#is_ticket_banner_game').prop('checked', false);
    return;
  }

  $('#is_ticket_banner_game').prop('checked', true)
  $('#ticket_banner_casino_game').hide();
}

function clearInputs () {
  $('#module_id').val('');
  $('#client_id').val('');
  $('#product_id').val('');
  $('#drop-down-id').prop('selectedIndex',0);
}

function clearInputsVegas () {
  $('#micro_gaming_vegas #module_id').val('');
  $('#micro_gaming_vegas #client_id').val('');
  $('#micro_gaming_vegas #product_id').val('');
  $('#micro_gaming_vegas #drop-down-id').prop('selectedIndex',0);
}

function savePokerCategories($configId) {
  $(`#configurations-${$configId}`)[0].value = $('#poker-categories')[0].value;
}

$(document).ready(function() {
  const hasTableInputs = $('.has-table');
  const tableIdData = $('#table-id-data').data('table-id');

  hasTableInputs.each((index, input) => {
    if ($(input).is(':checked')) {
      return createTableIdField($(input), tableIdData);
    }
  });
})

$(':radio').on('click', function (input) {
  createTableIdField($(input.target), '');
})
